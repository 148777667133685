import React, { useCallback, useContext, useEffect, useState } from "react";
import logo from "../../Images/logo-mini.png";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Button from "@mui/material/Button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPhotoFilm,
  faEllipsisH,
  faCircleInfo,
} from "@fortawesome/free-solid-svg-icons";
import { styled } from "@mui/material/styles";
import { Link, useNavigate, useParams } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Breadcrumbs from "@mui/material/Breadcrumbs";
import { List, ListItem, Popover } from "@mui/material";
import profile from "../../Images/Ellipse1.png";
import chatmedia from "../../Images/cat-five1.png";
import { Modal, Box } from "@mui/material";
import ContinueChatModal from "./ContinueChatModal";
import MsgBar from "./InboxChat/MsgBar";
import Popupmsg from "./InboxChat/PopupMsg";
import {
  socket,
  socketMessageContext,
} from "../../context/SocketMessageContext";
import {
  acceptOrRejectOrEndInvitation,
  endDispute,
  referInvitatationAcceptReject,
} from "../../services";
import { notifyError, notifySuccess } from "../../toastNotifications";
import AddDispute from "../Profile/AllContracts/AddDispute";
import xmark from "../../Images/x-mark.png";
import Review from "../Profile/AllContracts/Review";

function Index() {
  const navigate = useNavigate();
  const { msgId } = useParams();
  const { socketMessageData, handleMessageSetter } =
    useContext(socketMessageContext);
  const [popoverAnchorEl, setPopoverAnchorEl] = useState(null);
  const [popoverAnchorElTwo, setPopoverAnchorElTwo] = useState(null);
  const [showPopupMsg, setShowPopupMsg] = useState(true);
  const [userId, setUserId] = useState(
    JSON.parse(localStorage.getItem("user"))._id || null
  );
  const [msgData, setMsgData] = useState(null);
  const [isOwner, setIsOwner] = useState(null);
  const [timeLeft, setTimeLeft] = useState("");
  const [endTime, setEndTime] = useState(
    localStorage.getItem("endTime") || null
  ); // Retrieve from local storage
  // const containerRef = useRef(null); // Define the reference

  // const [open, setOpen] = useState(false);
  const [selectedMedia, setSelectedMedia] = useState(null);
  const [selectedMediaType, setSelectedMediaType] = useState("");

  const handleOpen = (data, type) => {
    setSelectedMedia(data);
    setSelectedMediaType(type);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setSelectedMedia(null);
    setSelectedMediaType("");
  };

  const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
  };

  const [selectedMediaArr, setSelectedMediaArr] = useState([]);

  const [open, setOpen] = useState(false);

  // const handleOpen = () => setOpen(true);
  // const handleClose = () => setOpen(false);

  const handlePopoverOpen = (event) => {
    setPopoverAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setPopoverAnchorEl(null);
  };

  const handlePopoverOpenTwo = (event) => {
    setPopoverAnchorElTwo(event.currentTarget);
  };
  const handlePopoverCloseTwo = () => {
    setPopoverAnchorElTwo(null);
  };

  const openPopover = Boolean(popoverAnchorEl);
  const openPopoverTwo = Boolean(popoverAnchorElTwo);
  const togglePopupMsg = () => {
    setShowPopupMsg((prev) => !prev);
  };

  const VisuallyHiddenInput = styled("input")({
    clip: "rect(0 0 0 0)",
    clipPath: "inset(50%)",
    height: 1,
    overflow: "hidden",
    position: "absolute",
    bottom: 0,
    left: 0,
    whiteSpace: "nowrap",
    width: 1,
  });

  const checkUrlType = (url) => {
    const extension = url.split(".").pop().toLowerCase();

    // Check the file extension
    if (
      extension === "jpg" ||
      extension === "jpeg" ||
      extension === "png" ||
      extension === "gif" ||
      extension === "bmp"
    ) {
      return "image";
    } else if (
      extension === "mp4" ||
      extension === "avi" ||
      extension === "mov" ||
      extension === "mkv" ||
      extension === "webm"
    ) {
      return "video";
    } else {
      return "unknown";
    }
  };

  const handleMediaSettler = async (media) => {
    let arr = [];

    for (let i = 0; i < media.length; i++) {
      const element = media[i];
      console.log("element is here -->", element);
      arr.push({
        key: arr.length,
        value: element,
        type: await checkUrlType(element),
      });
    }
    setSelectedMediaArr(arr);
  };

  const handleMessageSend = (val) => {
    if (val) {
      let data = {
        senderId: userId,
        receiverId: isOwner ? msgData?.recipients?._id : msgData?.senders?._id,
        message: val,
        messageId: msgId,
        media: null,
      };
      console.log("data", data);
      socket.emit("message", data);
      socket.on("message", (msg) => {
        console.log("message response message:", msg);
        handleMessageSetter(msg);
      });
      return () => socket.removeAllListeners();
    } else {
      notifyError("Please type your message");
    }
  };

  const handleMediaSend = (media) => {
    console.log("media", media);
    if (media) {
      let data = {
        senderId: userId,
        receiverId: isOwner ? msgData?.recipients?._id : msgData?.senders?._id,
        message: null,
        messageId: msgId,
        media: media,
      };
      console.log("data", data);
      socket.emit("addMedia", data);
      console.log("socket", socket);
      socket.on("addMedia", (msg) => {
        console.log("addmedia response message:", msg);
        handleMessageSetter(msg);
      });
      return () => socket.removeAllListeners();
    } else {
    }
  };

  // Countdown timer logic
  const calculateTimeLeft = (val) => {
    const endDate = new Date(val);
    const now = new Date();
    const difference = endDate - now;

    let timeLeft = "";
    if (endTime) {
      if (difference > 0) {
        const hours = Math.floor(difference / (1000 * 60 * 60));
        const minutes = Math.floor(
          (difference % (1000 * 60 * 60)) / (1000 * 60)
        );
        const seconds = Math.floor((difference % (1000 * 60)) / 1000);
        timeLeft = `${minutes < 10 ? `0${minutes}` : minutes} : ${seconds < 10 ? `0${seconds}` : seconds}`;
      } else {
        timeLeft = "Expired";
        // if (msgId) {
        //   socket.on("getMessage", (msg) => {
        //     setMsgData(msg);
        //     setIsOwner(
        //       msg &&
        //         msg?.messageList &&
        //         msg?.messageList.length > 0 &&
        //         JSON.parse(localStorage.getItem("user"))._id &&
        //         msg?.messageList?.[0].sender ===
        //           JSON.parse(localStorage.getItem("user"))._id
        //     );
        //     if (msg?.endTime) {
        //       setEndTime(msg.endTime);
        //       localStorage.setItem("endTime", msg.endTime); // Save endTime to local storage
        //     }
        //   });
        // }
      }
    }

    return timeLeft;
  };

  const handleAcceptOrRejectPerPosal = async (isStatus) => {
    console.log(JSON.parse(localStorage.getItem("user"))._id);
    let data = {
      notesId: msgData?.notesId || "",
      userId: msgData?.recipients._id || "",
      status: isStatus,
    };
    try {
      const response = await referInvitatationAcceptReject(data);
      console.log(
        "referInvitatationAcceptReject response is here -->",
        response
      );
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        navigate(-1);
        // navigate(`/message-send/${id}`)
      }
    } catch (error) {
      console.error("Error in referInvitatationAcceptReject :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    }
  };


  const handleEndDispute = async (msgId) => {
    let data = { messageId: msgId };
    try {
      const response = await endDispute(data);
      console.log("endDispute response is here -->", response);
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        handleMessageSend("Dispute Resolved")
      }
    } catch (error) {
      console.error("Error in endDispute :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      handlePopoverClose();
    }
  };

  const handleEndContract = async () => {
    // Handle ending the contract logic
    let data = {
      messageId: msgId,
      isStatus: "3",
    };
    try {
      const response = await acceptOrRejectOrEndInvitation(data);
      console.log(
        "acceptOrRejectOrEndInvitation response is here -->",
        response
      );
      if (response.statusCode === 200) {
        notifySuccess(response.message);
        handleMessageSend("contract Ended");
        navigate(`/inbox-message`);
      }
    } catch (error) {
      console.error("Error in acceptOrRejectOrEndInvitation :", error);
      notifyError(error?.response?.data?.message || `Something went wrong`);
    } finally {
      handlePopoverClose();
    }
  };

  useEffect(() => {
    if (msgId) {
      let data = {
        messageId: msgId,
        userId: userId,
        isRead: 1,
      };
      socket.emit("getMessage", data);
    }
  }, [msgId, socket, userId]);

  useEffect(() => {
    socket.on("message", (data) => {
      handleMessageSetter(data);
    });
  }, [handleMessageSetter, socket]);

  useEffect(() => {
    // if (containerRef.current) {
    //   containerRef.current.scrollTop = containerRef.current.scrollHeight;
    // }
    if (msgId) {
      socket.on("getMessage", (msg) => {
        setMsgData(msg);
        setIsOwner(
          msg &&
          msg?.messageList &&
          msg?.messageList.length > 0 &&
          JSON.parse(localStorage.getItem("user"))._id &&
          msg?.messageList?.[0].sender ===
          JSON.parse(localStorage.getItem("user"))._id
        );
        if (msg?.endTime) {
          setEndTime(msg.endTime);
          localStorage.setItem("endTime", msg.endTime); // Save endTime to local storage
        } else {
          localStorage.removeItem("endTime"); // Save endTime to local storage
        }

        // let media = msg?.messageList?.[0]?.media || [];
        // console.log("fjjfejcf", media)
        // if (media.length == 0) {
        //   setSelectedMediaArr([]);
        // } else {
        //   handleMediaSettler(media);
        // }
      });
    }
    // }, [socket, msgData]);
  }, [socket, msgId]);

  useEffect(() => {
    if (endTime) {
      const timer = setInterval(() => {
        setTimeLeft(calculateTimeLeft(endTime));
      }, 1000); // Adjusted to update every second

      return () => clearInterval(timer);
    }
  }, [endTime]);

  // console.log(`selectedMediaArr`, selectedMediaArr)

  useEffect(() => {
    console.log("socketMessageData in MESSAGE SEND ===>", socketMessageData);
    if (socketMessageData || timeLeft === "Expired") {
      let data = {
        messageId: msgId,
        userId: userId,
      };
      socket.emit("getMessage", data);
      socket.on("getMessage", (msg) => {
        setMsgData(msg);
        setIsOwner(
          msg &&
          msg?.messageList &&
          msg?.messageList.length > 0 &&
          JSON.parse(localStorage.getItem("user"))._id &&
          msg?.messageList?.[0].sender ===
          JSON.parse(localStorage.getItem("user"))._id
        );

        // let media = msg?.messageList?.[0]?.media || [];
        // console.log("fjjfejcf", msg)
        // if (media.length == 0) {
        //   setSelectedMediaArr([]);
        // } else {
        //   handleMediaSettler(media);
        // }
      });
    }
  }, [msgId, socket, socketMessageData, userId, endTime]);

  const chatBottomRef = document.querySelector("#chat-scroll");

  const scrollToBottom = useCallback(() => {
    if (chatBottomRef) {
      chatBottomRef.scrollTo({
        top: chatBottomRef.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [chatBottomRef]);

  useEffect(() => {
    scrollToBottom();
  }, [msgData]);

  const [theme, setTheme] = useState(localStorage.getItem("theme") || "light");
  useEffect(() => {
    const storedTheme = localStorage.getItem("theme");
    if (storedTheme) {
      setTheme(storedTheme);
    }
  }, []);
  // const now = new Date();
  // const DateTime = now.toLocaleString('en-US', {
  //   hour: '2-digit',
  //   minute: '2-digit',
  //   hour12: true,
  // });

  const timeFormatter = (time) => {
    let now = new Date(time);
    now = now.toLocaleString("en-US", {
      hour: "2-digit",
      minute: "2-digit",
      hour12: true,
    });
    return now;
  };

  useEffect(() => {
    localStorage.setItem("theme", theme);
  }, [theme]);

  console.log("msgData", msgData)

  return (
    <div>
      <div
        className={` ' container-fluid background-main pb-5 theme-switcher ${theme === "dark" ? "black-color" : "light-theme"
          }`}
      >
        <div className="container">
          <h2 className="search-note-h2 pt-5">Message</h2>
          <Breadcrumbs aria-label="breadcrumb" className="breadcrumbs">
            <Link
              underline="hover"
              color="inherit"
              to="/home"
              className={` ' theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                }`}
            >
              Home
            </Link>
            <Link
              underline="hover"
              color="inherit"
              to="/user-list"
              className={` ' theme-switcher ${theme === "dark" ? "dark-theme-only-white" : "light-theme"
                }`}
            >
              User listing
            </Link>
            {/* <Link
              underline="hover"
              color="inherit"
              to="/user-detail"
              className={` ' theme-switcher ${
                theme === "dark" ? "dark-theme-only-white" : "light-theme"
              }`}
            >
              User detail
            </Link> */}
            <Typography color="text.primary">
              <b>Message</b>
            </Typography>
          </Breadcrumbs>
        </div>
        <div
          className={` ' search-note m-auto mt-4 theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
            }`}
        >
          <div className="px-4">
            <div className="message-chat">
              <div className="message-header">
                <div className="countdown-timer text-danger text-center fw-bold pt-4 mb-2">
                  {msgData &&
                    msgData?.messageList &&
                    msgData?.messageList.length > 1 &&
                    timeLeft !== "Expired"
                    ? timeLeft
                    : ""}
                </div>
                {msgData && !isOwner && msgData?.isStatus == "3" ? (
                  <div
                    className={` bar-icon mt-2 theme-switcher ${theme === "dark" ? "bar-icon-set" : "light-theme"
                      }`}
                    onClick={handlePopoverOpenTwo}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </div>
                ) : msgData && !isOwner ? (
                  ""
                ) : msgData && msgData?.isStatus == "2" ? (
                  ""
                ) : msgData && msgData?.isStatus == "3" ? (
                  <div
                    className={` bar-icon mt-2 theme-switcher ${theme === "dark" ? "bar-icon-set" : "light-theme"
                      }`}
                    onClick={handlePopoverOpenTwo}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </div>
                ) : (
                  <div
                    className={` bar-icon mt-2 theme-switcher ${theme === "dark" ? "bar-icon-set" : "light-theme"
                      }`}
                    onClick={handlePopoverOpen}
                  >
                    <FontAwesomeIcon icon={faEllipsisH} />
                  </div>
                )}
                {msgData
                  && msgData.proposedMessage
                  &&
                  (msgData?.recipients?._id == userId || !msgData?.isRefered && msgData?.isReferedStatus == 1)
                  ? (
                      <>
                        <FontAwesomeIcon
                          icon={faCircleInfo}
                          size="2x"
                          className="float-end mt-2 me-2 perposal-info"
                          onClick={togglePopupMsg}
                        />
                        {showPopupMsg && (
                          <Popupmsg
                            data={msgData}
                            handleAcceptOrRejectPerPosal={
                              handleAcceptOrRejectPerPosal
                            }
                            msgId={msgId}
                          />
                        )}
                      </>
                    ) : (
                        <></>
                      )}
                <Popover
                  open={openPopover}
                  anchorEl={popoverAnchorEl}
                  onClose={handlePopoverClose}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <List
                    className={` ' theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                      }`}
                  >
                    <span>
                      {msgData?.isDispute == 0
                        ? <AddDispute
                          msgId={msgId}
                          handleCreateDisputeMessage={() => {
                            handleMessageSend("Dispute created")
                            handlePopoverClose()
                          }}
                        />
                        : msgData?.isDispute == 1
                          ? <ListItem button onClick={() => handleEndDispute(msgId)}>
                            End Dispute
                          </ListItem>
                          : ''}
                    </span>
                    <hr className="p-0 m-0" />
                    <ListItem button onClick={handleEndContract}>
                      End Contract
                    </ListItem>
                  </List>
                </Popover>

                <Popover
                  open={openPopoverTwo}
                  anchorEl={popoverAnchorElTwo}
                  onClose={handlePopoverCloseTwo}
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "center",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "center",
                  }}
                >
                  <List
                    className={`theme-switcher ${theme === "dark" ? "dark-theme-only-color" : "light-theme"
                      }`}
                  >
                    <span>
                      <Review
                        msgId={msgId}
                        userId={
                          isOwner
                            ? msgData?.recipients?._id
                            : msgData?.senders?._id
                        }
                      />
                    </span>
                  </List>
                </Popover>
                <div className="chat-section pt-5 mb-5">
                  {/* <div className="d-flex w-100 justify-content-center align-items-center my-4">
                    <div className="chat-content p-3 w-100 d-flex w-100 justify-content-center align-items-center" style={{ backgroundColor: '#c1efab', borderRadius: '20px' }}>
                      {msgData && msgData.proposedMessage}
                    </div>
                  </div> */}
                  <div
                    className="client-side-chat"
                    id="chat-scroll"
                    style={{ maxHeight: "20rem", overflowY: "scroll" }}
                  >
                    {/* <div className="d-flex"> 
                      <span>
                        <img src={profile} alt="" className="chat-profile" />
                      </span>
                      <span>
                        <img src={chatmedia} alt="" className="chat-media mx-3" />
                      </span>
                    </div> */}
                    {msgData &&
                      msgData?.messageList &&
                      msgData?.messageList.length > 0 ? (
                      <>
                        {msgData?.messageList.map((val, index) => {
                          let isSender = val?.sender === userId;
                          let sendername = val?.senderdetails?.name || "";
                          let DateTime = val?.createdAt || "";
                          let senderImage =
                            val?.senderdetails?.profileImage || "";
                          let receivername = val?.recipient?.name || "";
                          let receiverImage =
                            val?.recipient?.profileImage || "";
                          let message = val?.message || null;
                          let mediaArr = val?.media || [];
                          let isAdmin = val?.senderdetails?.role == "admin";

                          if (isSender) {
                            return (
                              <div
                                className="d-flex user-side-chat-fixed py-2"
                                key={index}
                              >
                                <div className="d-flex flex-column user-side-chat-fixed py-2 w-100">
                                  <div className="d-flex justify-content-end w-100 flex-wrap">
                                    {mediaArr &&
                                      mediaArr.map((data, index) => {
                                        let imgType = checkUrlType(data);
                                        return (
                                          <div
                                            className="m-2"
                                            style={{
                                              width: "9rem",
                                              minWidth: "9rem",
                                              height: "8rem",
                                              position: "relative",
                                            }}
                                            key={index}
                                            onClick={() =>
                                              handleOpen(data, imgType)
                                            }
                                          >
                                            {imgType === "image" ? (
                                              <img
                                                src={data}
                                                alt="Preview"
                                                className="object-fit-cover"
                                                style={{
                                                  borderRadius: "10px",
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              />
                                            ) : imgType === "video" ? (
                                              <video
                                                controls
                                                className="object-fit-cover"
                                                style={{
                                                  borderRadius: "10px",
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              >
                                                <source
                                                  src={data}
                                                  type="video/mp4"
                                                />
                                                {/* Your browser does not support the video tag. */}
                                              </video>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>

                                  {message && (
                                    <div className="d-flex justify-content-end">
                                      <div
                                        className={`user-chat-content p-3 mr-3 theme-switcher ${theme === "dark"
                                          ? "message-bar-color"
                                          : "light-theme"
                                          }`}
                                      >
                                        <div className="user-chat-name text-end">
                                          <b>
                                            {/* {!isOwner ? receivername : sendername}  */}
                                            You
                                            </b>
                                        </div>
                                        {message}
                                        <div className="message-time">
                                          {timeFormatter(DateTime)}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                                <div
                                  className="mr-2"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    display: "contents",
                                  }}
                                >
                                  <img
                                    src={!isOwner ? receiverImage : senderImage}
                                    alt=""
                                    className="chat-profile object-fit-cover"
                                  />
                                  ,
                                </div>
                              </div>
                            );
                          } else {
                            return (
                              <div className="d-flex py-2" key={index}>
                                <div
                                  className="mr-2"
                                  style={{
                                    width: "50px",
                                    height: "50px",
                                    display: "contents",
                                  }}
                                >
                                  <img
                                    src={isAdmin ? senderImage : isOwner ? receiverImage : senderImage}
                                    alt=""
                                    className="chat-profile object-fit-cover"
                                  />
                                </div>
                                <div className="d-flex ms-2 flex-column user-side-chat-fixed py-2 w-100">
                                  <div className="d-flex w-100 flex-wrap">
                                    {mediaArr &&
                                      mediaArr.map((data, index) => {
                                        let imgType = checkUrlType(data);
                                        return (
                                          <div
                                            className="m-2"
                                            style={{
                                              width: "9rem",
                                              minWidth: "9rem",
                                              height: "8rem",
                                              position: "relative",
                                            }}
                                            key={index}
                                            onClick={() =>
                                              handleOpen(data, imgType)
                                            }
                                          >
                                            {imgType === "image" ? (
                                              <img
                                                src={data}
                                                alt="Preview"
                                                className="object-fit-cover"
                                                style={{
                                                  borderRadius: "10px",
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              />
                                            ) : imgType === "video" ? (
                                              <video
                                                controls
                                                className="object-fit-cover"
                                                style={{
                                                  borderRadius: "10px",
                                                  width: "100%",
                                                  height: "100%",
                                                }}
                                              >
                                                <source
                                                  src={data}
                                                  type="video/mp4"
                                                />
                                                {/* Your browser does not support the video tag. */}
                                              </video>
                                            ) : (
                                              <></>
                                            )}
                                          </div>
                                        );
                                      })}
                                  </div>
                                  {message && (
                                    <div className="d-flex">
                                      <div
                                        className={`chat-content p-3 mr-3 theme-switcher ${theme === "dark"
                                          ? "message-bar-color"
                                          : "light-theme"
                                          }`}
                                      >
                                        <div className="user-chat-name">
                                          <b>{isAdmin ? sendername : isOwner ? receivername : sendername}</b>
                                        </div>
                                        {message}
                                        <div className="message-time-left">
                                          {timeFormatter(DateTime)}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                                </div>
                              </div>
                              // <div className="d-flex flex-column py-2 overflow-x-scroll w-100" key={index}>
                              //   <span style={{ minWidth: `50px` }}>
                              //     <img src={isOwner ? receiverImage : senderImage} alt="" className="chat-profile object-fit-cover" />
                              //   </span>
                              //   <div className="d-flex w-100 flex-wrap">

                              //     {mediaArr && mediaArr.map((val, index) => {
                              //       return (
                              //         <div className='m-2' style={{ width: '9rem', minWidth: '9rem', height: '8rem', position: 'relative' }} key={index}>
                              //           {val.type === 'image' ? (
                              //             <img src={val.value} alt="Preview" className='object-fit-cover' style={{ borderRadius: '10px', width: '100%', height: '100%' }} />
                              //           ) : (
                              //             <video controls className='object-fit-cover' style={{ borderRadius: '10px', width: '100%', height: '100%' }}>
                              //               <source src={val.value} type="video/mp4" />
                              //               Your browser does not support the video tag.
                              //             </video>
                              //           )}
                              //         </div>
                              //       )
                              //     })}
                              //   </div>

                              //   <div className="chat-content p-3 ms-3">
                              //     {message}
                              //   </div>
                              // </div>
                            );
                          }
                        })}
                      </>
                    ) : null}
                  </div>
                  {/* {msgData && msgData.proposedMessage
                    ?
                    msgData?.isReferedStatus == 1 && msgData.isStatus == 0
                      ?
                      <div className="m-auto pb-4 d-flex flex-wrap justify-content-between">
                        <button className="save-draft-btn mt-4" onClick={() => handleAcceptOrRejectPerPosal('3')}>Reject</button>
                        <span className="modal-button">
                          <button className="perposal-accept-reject-btn" onClick={() => handleAcceptOrRejectPerPosal('2')}>Accept</button>
                        </span>
                      </div>
                      : msgData?.isReferedStatus == 3 && msgData.isStatus == 2
                        ?
                        <div className="m-auto pb-4 d-flex flex-wrap justify-content-center">
                          <button className="save-draft-btn mt-4">Proposal declined</button>
                        </div>
                        // : msgData?.isReferedStatus == 2 && msgData.isStatus == 1
                        //   ?
                        //   <div className="m-auto pb-4 d-flex flex-wrap justify-content-center">
                        //     <button className="save-draft-btn mt-4">Proposal decswdxlined</button>
                        //   </div>
                        : ''
                    : ''
                  } */}
                </div>
                {isOwner
                  ? (
                    msgData && msgData?.isStatus == "2" ? (
                      <div className="d-flex justify-content-center pb-2">
                        <span className="text-danger">
                          {`${msgData?.recipients?.name} rejected your invitation.`}{" "}
                        </span>
                      </div>
                    )
                      : msgData &&
                        msgData?.messageList &&
                        msgData?.messageList.length > 1 &&
                        msgData?.isDispute == 1 ? (
                        <div className="w-100 d-grid mt-4">
                          <MsgBar
                            handleMessageSend={handleMessageSend}
                            handleMediaSend={handleMediaSend}
                          />
                        </div>
                      )
                        : (msgData &&
                          msgData?.messageList &&
                          msgData?.messageList.length > 1 &&
                          msgData?.continueChat == 0 &&
                          msgData?.isStatus != "3" && msgData?.isStatus != "2") ||
                          (timeLeft === "Expired" && msgData?.isStatus != "3" && msgData?.isStatus != "2")
                          ? (
                            <div className="w-100 d-grid justify-content-center mt-4">
                              <ContinueChatModal
                                fixedAmount={true}
                                hoursPrice={
                                  (msgData && msgData?.recipients?.hoursPrice) || 0
                                }
                                name={(msgData && msgData?.recipients?.name) || ""}
                                msgId={(msgData && msgData?._id) || null}
                                userId={(msgData && msgData?.recipients?._id) || null}
                                endTime={endTime} // Pass endTime as prop
                                startTimer={() => {
                                  setEndTime(
                                    new Date(
                                      Date.now() + msgData?.endTime * 60 * 60 * 1000
                                    )
                                  );
                                }}
                              />
                            </div>
                          )
                          : msgData &&
                            msgData?.messageList &&
                            msgData?.messageList.length > 1 &&
                            msgData?.continueChat == 1 ? (
                            <div className="w-100 d-grid mt-4">
                              <MsgBar
                                handleMessageSend={handleMessageSend}
                                handleMediaSend={handleMediaSend}
                              />
                            </div>
                          )
                            : (
                              ""
                            )
                  )
                  : msgData && msgData?.isStatus == "2"
                    ? (
                      <div className="d-flex justify-content-center pb-2">
                        <span className="text-danger">
                          {`You have rejected the invitation request.`}{" "}
                        </span>
                      </div>
                    )
                    : msgData &&
                      msgData?.messageList &&
                      msgData?.messageList.length == 1 &&
                      msgData?.isStatus != "2" &&
                      msgData?.isStatus != "3" ? (
                      <div className="w-100 d-grid mt-4">
                        <MsgBar
                          handleMessageSend={handleMessageSend}
                          handleMediaSend={handleMediaSend}
                        />
                      </div>
                    )
                      : msgData &&
                        msgData?.messageList &&
                        msgData?.messageList.length > 1 &&
                        msgData?.isDispute == 1 ? (
                        <div className="w-100 d-grid mt-4">
                          <MsgBar
                            handleMessageSend={handleMessageSend}
                            handleMediaSend={handleMediaSend}
                          />
                        </div>
                      )
                        : (msgData &&
                          msgData?.messageList &&
                          msgData?.messageList.length > 1 &&
                          msgData?.continueChat == 0 &&
                          msgData?.isStatus != "3") ||
                          (timeLeft === "Expired" && msgData?.isStatus != "3")
                          ? (
                            <div className="w-100 d-grid justify-content-center mt-4">
                              <ContinueChatModal
                                fixedAmount={false}
                                title={`Update Your Hourly Price`}
                                hoursPrice={
                                  (msgData && msgData?.senders?.hoursPrice) || 0
                                }
                                name={(msgData && msgData?.senders?.name) || ""}
                                msgId={(msgData && msgData?._id) || null}
                                userId={(msgData && msgData?.senders?._id) || null}
                              />
                            </div>
                          )
                          : msgData &&
                            msgData?.messageList &&
                            msgData?.messageList.length > 1 &&
                            msgData?.continueChat == 1 &&
                            msgData?.isStatus != "3" ? (
                            <div className="w-100 d-grid mt-4">
                              <MsgBar
                                handleMessageSend={handleMessageSend}
                                handleMediaSend={handleMediaSend}
                              />
                            </div>
                          )
                            : (
                              ""
                            )}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby="modal-title"
        aria-describedby="modal-description"
      >
        <Box
          sx={style}
          style={{ width: "700px", maxHeight: "80%", overflowY: "scroll" }}
          className="p-3"
        >
          <img
            src={xmark}
            alt=""
            className="x-mark  position-absolute right-4 z-index-999"
            onClick={handleClose}
          />
          {selectedMediaType === "image" ? (
            <img
              src={selectedMedia}
              alt="Full Preview"
              style={{
                width: "100%",
                height: "100%",
                maxHeight: "80%",
                objectFit: "cover",
              }}
            />
          ) : (
            <video
              controls
              style={{
                width: "100%",
                height: "50%",
                maxHeight: "50%",
                objectFit: "cover",
              }}
            >
              <source src={selectedMedia} type="video/mp4" />
            </video>
          )}
        </Box>
      </Modal>
    </div>
  );
}
export default Index;

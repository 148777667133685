import React from "react";
import Footer from "../../Footer";
import { Helmet } from "react-helmet-async";

const TermsAndConditions = () => {
  return (
    <>
      <Helmet>
        <title>Terms and Conditions for Using AskHuman AI Services </title>
        <meta
          name="description"
          content=" 
 	
Review the terms and conditions for using AskHuman AI's services. Understand the rules, rights, and responsibilities that govern your use."
        />
      </Helmet>
      <div className="container privacy-policy-container mt-3 pb-5 mt-5 pt-4">
        <h1 className="text-center">Terms and Conditions</h1>
        <p className="mt-3">
          <strong>Introduction</strong>
        </p>
        <p>
          Welcome to Ask Human. These Terms and Conditions govern your use of
          our platform and services. By accessing or using our website, you
          agree to comply with and be bound by these terms. If you do not agree
          with any part of these terms, please do not use our services.
        </p>
        <p className="mt-3">
          <strong>Account Registration</strong>
        </p>
        <p>
          1. Eligibility: You must be at least 18 years old to create an account
          and use our services.
        </p>
        <p>
          2. Account Information: You agree to provide accurate, current, and
          complete information during the registration process and to update
          such information to keep it accurate, current, and complete.
        </p>
        <p>
          3. Account Security: You are responsible for maintaining the
          confidentiality of your account login information and for all
          activities that occur under your account. Notify us immediately of any
          unauthorized use of your account.
        </p>
        <p className="mt-3">
          <strong>Services</strong>
        </p>
        <p>
          1. Asking Questions: Users can post questions and invite experts to
          provide answers. Users are responsible for the content and accuracy of
          their questions.
        </p>
        <p>
          2. Providing Answers: Experts can accept invitations to provide
          answers. Experts must provide accurate and reliable information based
          on their expertise.
        </p>
        <p>
          3. Payments: Users pay for expert advice on an hourly basis. Payment
          terms are clearly stated at the time of transaction. Experts receive
          payment for accepted invitations and completed sessions.
        </p>
        <p className="mt-3">
          <strong>Fees and Refunds</strong>
        </p>
        <p>
          1. Invitation Fee: A $1 fee is charged for inviting an expert to chat.
          This fee is refundable if the invitation is not accepted.
        </p>
        <p>
          2. Payment for Services: Users pay experts based on the duration and
          complexity of the chat session. Fees are charged on an hourly basis.
        </p>
        <p>
          3. Refund Policy: If you are not satisfied with the answer, you may
          dispute the payment. Refunds are subject to our dispute resolution
          process.
        </p>
        <p className="mt-3">
          <strong>User Conduct</strong>
        </p>
        <p>
          1. Prohibited Activities: Users and experts agree not to engage in any
          activities that are illegal, harmful, or violate the rights of others.
          This includes, but is not limited to, harassment, discrimination, and
          fraudulent behavior.
        </p>
        <p>
          2. Content Guidelines: All content posted on the platform must be
          accurate, lawful, and not misleading. Users and experts are prohibited
          from posting content that is offensive, defamatory, or violates
          intellectual property rights.
        </p>
        <p className="mt-3">
          <strong>Dispute Resolution</strong>
        </p>
        <p>
          1. Disputes: In the event of a dispute between users and experts, our
          support team will review the case and make a determination based on
          the evidence provided.
        </p>
        <p>
          2. Resolution: We aim to resolve disputes fairly and promptly. Our
          decision on disputes is final and binding.
        </p>
        <p className="mt-3">
          <strong>Intellectual Property</strong>
        </p>
        <p>
          1. Ownership: All content on the platform, including text, graphics,
          logos, and software, is the property of Ask Human or its licensors and
          is protected by copyright, trademark, and other intellectual property
          laws.
        </p>
        <p>
          2. User Content: By posting content on our platform, you grant Ask
          Human a non-exclusive, royalty-free, worldwide license to use,
          reproduce, modify, and distribute your content in connection with our
          services.
        </p>
        <p className="mt-3">
          <strong>Limitation of Liability</strong>
        </p>
        <p>
          1. No Warranty: Ask Human provides services "as is" without any
          warranties or guarantees. We do not guarantee the accuracy,
          reliability, or completeness of any information provided by experts.
        </p>
        <p>
          2. Liability: In no event shall Ask Human be liable for any indirect,
          incidental, special, or consequential damages arising out of or in
          connection with the use of our services.
        </p>
        <p className="mt-3">
          <strong>Termination</strong>
        </p>
        <p>
          1. Termination by User: You may terminate your account at any time by
          contacting customer support.
        </p>
        <p>
          2. Termination by Ask Human: We reserve the right to suspend or
          terminate your account if you violate these terms or engage in any
          prohibited activities.
        </p>
        <p className="mt-3">
          <strong>Changes to Terms</strong>
        </p>
        <p>
          We may update these Terms and Conditions from time to time. Any
          changes will be posted on this page, and we will notify you of
          significant changes. Your continued use of our services after the
          changes take effect constitutes your acceptance of the new terms.
        </p>
        <p className="mt-3">
          <strong>Contact Us</strong>
        </p>
        <p>
          If you have any questions or concerns about these Terms and
          Conditions, please contact our customer support team at
          support@askhuman.com.
        </p>
      </div>
      <Footer />
    </>
  );
};

export default TermsAndConditions;

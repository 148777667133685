import React, { useState } from 'react';
import { Link, NavLink, useLocation } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';
import logo from '../../Images/logo-mini.png';
import Badge from '@mui/material/Badge';
import NotificationsIcon from '../../Images/NotificationsIcon.png';
import profile from '../../Images/Ellipse28236.png';


const pages = [
  { label: "Home", href: "/home" },
  { label: "About Us", href: "/about-us" },
  { label: "Blog", href: "/blog" },
  { label: "FAQ", href: "/FAQ" },
  //   { label: 'Contact Us', path: '/' }
//   { label: 'Contact Us', path: '/' }
];

const settings = ['Profile', 'Account', 'Dashboard', 'Logout', 'Login']; // Added 'Login' for logout path

function Navbar() {
  const location = useLocation();
  const [menuOpen, setMenuOpen] = useState(false);

  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };

  const [anchorElNav, setAnchorElNav] = useState(null);
  const [anchorElUser, setAnchorElUser] = useState(null);

  const handleOpenNavMenu = (event) => {
    setAnchorElNav(event.currentTarget);
  };
  const handleOpenUserMenu = (event) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseNavMenu = () => {
    setAnchorElNav(null);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  return (
    <div className='container-fluid  m-0 p-0 pb-5'>
      <AppBar position="static custom-header-compare">
        <Container maxWidth="xl">
          <Toolbar disableGutters>
            <Typography
              variant="h6"
              noWrap
              component={NavLink}
              to="/home"
              sx={{
                mr: 2,
                display: { xs: 'none', md: 'flex' },
                fontFamily: 'monospace',
                fontWeight: 800,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
              style={{ color: 'black' }}
            >
              <img src={logo} alt="" className='logo-nav p-2' />
            </Typography>

            <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleOpenNavMenu}
                color="inherit"
              >
                <MenuIcon />
              </IconButton>
              <Menu
                id="menu-appbar"
                anchorEl={anchorElNav}
                anchorOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
                keepMounted
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                open={Boolean(anchorElNav)}
                onClose={handleCloseNavMenu}
                sx={{
                  display: { xs: 'block', md: 'none' },
                }}
              >
                {pages.map((page) => (
                  <MenuItem key={page.label} onClick={handleCloseNavMenu}>
                    <a
                      href={page.href}
                      style={{ textDecoration: 'none', color: 'inherit' }}
                    >
                      {page.label}
                    </a>
                  </MenuItem>
                ))}
              </Menu>
            </Box>
            
            <Typography
              variant="h5"
              noWrap
              component="a"
              href="#app-bar-with-responsive-menu"
              sx={{
                mr: 2,
                display: { xs: 'flex', md: 'none' },
                flexGrow: 1,
                fontFamily: 'monospace',
                fontWeight: 700,
                letterSpacing: '.3rem',
                textDecoration: 'none',
              }}
              style={{ color: 'black' }}
            >
              <img src={logo} alt="" className='logo-nav p-2' />
            </Typography>
            <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }} className="ps-5">
              {pages.map((page) => (
                <Button
                 
                  sx={{ my: 2, color: 'black', display: 'block', borderBottom: location.pathname === page.path ? '2px solid #A50C93' : 'none' }}
                  className='nav-typography'
                >
                  <a  key={page.label}
                  component={NavLink}
                  href={page.href}
                  style={{ my: 2, color: 'black', display: 'block', borderBottom: location.pathname === page.path ? '2px solid #A50C93' : 'none', textDecoration:"none" }}
                  className='nav-typography'
                  onClick={handleCloseNavMenu}>{page.label}</a>
                </Button>
              ))}
            </Box>

            <Box sx={{ flexGrow: 0 }}>
              <Link to="/login"><button className='Login-btn'>Log In</button></Link>
              {/* <a href="#faq">sdfsd</a> */}
            
           
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </div>
  );
}

export default Navbar;
